<template>
  <v-container>
    <h1><textloader-component textKey="SamarbetspartnersRubrik" /></h1>

    <v-list>
      <v-list-group :value="false">
        <template v-slot:activator>
          <v-list-item-title>Swace Digital</v-list-item-title>
        </template>
        <v-list-item>
          <paragraph-component>
            <textloader-component textKey="SwacedigitalBrödtext" />
          </paragraph-component>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="false">
        <template v-slot:activator>
          <v-list-item-title>Arriver</v-list-item-title>
        </template>
        <v-list-item>
          <paragraph-component>
            <textloader-component textKey="ArriverBrödtext" />
          </paragraph-component>
        </v-list-item>
      </v-list-group>
      <v-list-group :value="false">
        <template v-slot:activator>
          <v-list-item-title>Unibap</v-list-item-title>
        </template>
        <v-list-item>
          <paragraph-component>
            <textloader-component textKey="UnibapBrödtext" />
          </paragraph-component>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  }
}
</script>
